import styled from "styled-components";

const COLOR_TEXT = ({ theme }) => theme.text;

export const Container = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;

  z-index: 1;
  position: fixed;
  top: 17px;
  right: 25px;

  background-color: transparent;
`;

export const IconBtn = styled.a`
  border-radius: 50px;
  padding: 12px 11px;

  font-size: 22px;
  text-decoration: none !important;
  cursor: pointer;
  color: ${COLOR_TEXT} !important;

  &:hover {
    transform: scale(1.5);
  }
`;