export const champagneBrandList = [
  {
    brand: "Cusumano",
    wines: [
      {
        name: "700 (brut metodo classico)",
        bottle: "40.00",
      }
    ]
  },
  {
    brand: "Veuve Clicquot",
    wines: [
      {
        name: "Cuvèe",
        bottle: "130.00",
      },
      {
        name: "La Grande Dame",
        bottle: "400.00",
      }
    ]
  },
  {
    brand: "Villa Rinaldi",
    wines: [
      {
        name: "Gran cuvèe (brut metodo classico)",
        bottle: "50.00",
      },
      {
        name: "Imperiale (brut metodo classico)",
        bottle: "70.00",
      }
    ]
  },
];

export const champagneList = [
  {
    name: "Bellavista",
    price: "55.00",
  },
  {
    name: "Champagne Perrier Jouët - Grand Brut",
    price: "120.00",
  },
];