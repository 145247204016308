const craftBeerList = [
  {
    category: "Epica",
    beers: [
      {
        name: "Bionda (A.P.A)",
        price: "7.00"
      },
      {
        name: "Bianca",
        price: "7.00"
      },
      {
        name: "Session IPA",
        price: "7.00"
      },
      {
        name: "IPA",
        price: "7.00"
      },
      {
        name: "Rossa (Belgian dubbel)",
        price: "7.00"
      },
      {
        name: "Imperial stont",
        price: "7.00"
      }
    ]
  },
  {
    beers: [
      {
        name: "Birra gluten free",
        price: "7.00"
      }
    ]
  },
];

export default craftBeerList;