import React from 'react';
import { useTranslation } from "react-i18next";

import * as Styles from '../styles';
import LineItem from '../components/lineItem';

import * as champagne from '../../../menu/champagneList';

export default function PageChampagne() {
  const { t } = useTranslation();

  return (
    <Styles.PageMenu id={t('menu-title_champagne_tag')}>
      <h2>{t('menu-title_champagne')}</h2>

      { champagne.champagneList.map((item, i) =>
        <LineItem
          key={i}
          name={item.name}
          price={<>{item.price} €</>}
        />
      )}

      { champagne.champagneBrandList.map((item, i) =>
        <Brand key={i} title={item.brand} list={item.wines}/>
      )}
    </Styles.PageMenu>
  );
}

const Brand = ({title, list}) => {
  return (
    <>
      <h3>{title}</h3>
      { list.map((wine, i) =>
        <LineItem
          key={i}
          name={wine.name}
          price={<>{wine.bottle} €</>}
        />
      )}
    </>
  )
}