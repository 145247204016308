import React from 'react';
import { useTranslation } from "react-i18next";

import Head from '../../layouts/head';
import Footer from '../../components/footer';
import SocialButtons from './components/socialBtn';
import LangBtns from './components/langBtns';
import Card from './components/card';

import PageWine from './pages/wines';
import PageChampagne from './pages/champagne';
import PageBeer from './pages/beer';
import PageCocktail from './pages/cocktail';
import PageDistillates from './pages/distillates';
import PageFoods from './pages/foods';

import * as Styles from './styles';
import * as Divider from '../../styles/dividerStyles';

import logo from '../../assets/img/logo.jpg';

export default function Page() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('menu-title')}
        description={t('menu-description')}
        keyword={t('menu-keyword')}
        path='menu'
      />
      <Main/>
    </>
  );
}

const Main = () => {
  const { t } = useTranslation();

  return (
    <>
      <LangBtns/>
      <SocialButtons/>

      <MenuPages/>

      <Styles.BtnToIndex smooth to="#index">{t('menu-to_index')}</Styles.BtnToIndex>

      {/* <Styles.BtnToWebSite to={t('url-home')}>{t('menu-home')}</Styles.BtnToWebSite> */}
    </>
  );
}

const MenuPages = () => {
  return (
    <Styles.Main>
      <Styles.ContainerMenu>
        <Index/>
        <Styles.MenuPageDivider/>

        <PageWine/>
        <Styles.MenuPageDivider/>

        <PageChampagne/>
        <Styles.MenuPageDivider/>

        <PageBeer/>
        <Styles.MenuPageDivider/>

        <PageCocktail/>
        <Styles.MenuPageDivider/>

        <PageDistillates/>
        <Styles.MenuPageDivider/>

        <PageFoods/>
        <Styles.MenuPageDivider/>

        <PageAllergens/>
        <Styles.MenuPageDivider/>

        <Footer/>
      </Styles.ContainerMenu>
    </Styles.Main>
  )
}

const Index = () => {
  const { t } = useTranslation();

  return (
    <Styles.PageMenu id="index">
      <div style={{textAlign: "center"}}>
        <Styles.Logo src={logo} alt="Logo skalunata cafe retro"/>
      </div>
      <h1>Menù</h1>

      <Divider.H30/>

      <Styles.ContainerIndex>
        <Card img="menu-wine-1" title={t('menu-title_wine')} to={`#${t('menu-title_wine_tag')}`}/>

        <Card img="menu-champagne-1" title={t('menu-title_champagne')} to={`#${t('menu-title_champagne_tag')}`}/>

        <Card img="menu-beer-2" title={t('menu-title_beer')} to={`#${t('menu-title_beer_tag')}`}/>

        <Card img="menu-cocktail-1" title={t('menu-title_cocktail')} to={`#${t('menu-title_cocktail_tag')}`}/>

        <Card img="menu-distillate-1" title={t('menu-title_distillates')} to={`#${t('menu-title_distillates_tag')}`}/>

        <Card img="menu-food-1" title={t('menu-title_food')} to={`#${t('menu-title_food_tag')}`}/>
      </Styles.ContainerIndex>
    </Styles.PageMenu>
  );
}

const PageAllergens = () => {
  const { t, ready } = useTranslation();

  if (!ready) return "loading translations...";

  const listAllergens = t('menu-list_allergens', { returnObjects: true });

  return (
    <Styles.PageMenu>
      <h4>{t('menu-title_allergens')}</h4>
      <Divider.H10/>

      <Styles.UlAllergenes style={{listStyleType: "decimal"}}>
        { listAllergens.map((item, i) =>
          <LiAllergens key={i} name={item.name} description={item.description} points={item.points}/>
        )}
      </Styles.UlAllergenes>

      <Divider.H30/>
      <small>{t('menu-allergens_info')}</small>
    </Styles.PageMenu>
  );
}

const LiAllergens = ({name, description, points}) => {
  return(
    <li>
      <strong>{name}</strong> {description} <br/>
      { points?.length &&
        <Styles.UlAllergenes style={{listStyleType: "decimal"}}>
          { points.map((point, i) =>
            <li key={i}>{point.point}</li>
          )}
        </Styles.UlAllergenes>
      }
    </li>
  );
}