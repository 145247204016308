const cocktailAnalcoholicList = [
  {
    name : "Virgin Colada",
    ingredients: "(Estratto D’ananas, Purea di cocco)",
  },
  {
    name : "Fresco",
    ingredients: "(Estratto di Mela Verde, Succo di Limone, Sciroppo Fiori di Sambuco)",
  },
  {
    name : "Virgin Sicilian Colada",
    ingredients: "(Estratto d’Ananas, Succo di Limone, Purea di Cocco, Latte di Mandorla)",
  },
  {
    name : "Virgin Mojito",
    ingredients: "(Succo di Lime, Menta, Soda, Zuchero, Estratto di Mela)",
  },
];

export default cocktailAnalcoholicList;