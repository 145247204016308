import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

import * as Styles from './styles';

import { SiFacebook, SiInstagram, SiTripadvisor } from "react-icons/si";

export default function SocialButtons() {
  return (
    <>
      <BrowserView>
        <Styles.Container>
          <Styles.IconBtn
            href={process.env.REACT_APP_FACEBOOK}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiFacebook style={{position: "relative", top: "2px", color: "blue"}}/>
          </Styles.IconBtn>

          <Styles.IconBtn
            href={process.env.REACT_APP_INSTAGRAM}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiInstagram style={{position: "relative", top: "2px", color: "purple"}}/>
          </Styles.IconBtn>

          <Styles.IconBtn
            href={process.env.REACT_APP_TRIPADVISOR}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiTripadvisor style={{position: "relative", top: "2px", color: "green"}}/>
          </Styles.IconBtn>
        </Styles.Container>
      </BrowserView>

      <MobileView>
        <Styles.Container>
          <Styles.IconBtn
            href={process.env.REACT_APP_FACEBOOK_ON_MOBILE}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiFacebook style={{position: "relative", top: "2px", color: "blue"}}/>
          </Styles.IconBtn>

          <Styles.IconBtn
            href={process.env.REACT_APP_INSTAGRAM_ON_MOBILE}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiInstagram style={{position: "relative", top: "2px", color: "purple"}}/>
          </Styles.IconBtn>

          <Styles.IconBtn
            href={process.env.REACT_APP_TRIPADVISOR}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiTripadvisor style={{position: "relative", top: "2px", color: "green"}}/>
          </Styles.IconBtn>
        </Styles.Container>
      </MobileView>
    </>
  );
}