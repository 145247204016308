import styled from "styled-components";
import { HashLink } from 'react-router-hash-link';

export const IndexCard = styled(HashLink)`
  margin: auto;
  width: 100%;
  max-width: 350px;

  display: block;
  text-decoration: none;

  &:hover, &:active {
    text-decoration: none;
  }
`;

export const IndexCardImg = styled.div`
  height: 200px;
  width: 100%

  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-position: center center;
`;

export const IndexCardTitle = styled.div`
  position: relative;
  top: 150px;

  padding-left: 8px;
  width: 150px;

  font-size: 1.25rem;
  line-height: 2.5rem;
  background: black;
  color: white;
`;