import React from 'react';
import { useTranslation } from "react-i18next";

import LineItem from '../components/lineItem';

import * as Styles from '../styles';

import brandyList from '../../../menu/brandyList';
import whiskyList from '../../../menu/whiskyList';
import ginList from '../../../menu/ginList';
import vodkaList from '../../../menu/vodkaList';

export default function PageDistillates() {
  const { t } = useTranslation();

  return (
    <Styles.PageMenu id={t('menu-title_distillates_tag')}>
      <h2>{t('menu-title_distillates')}</h2>

      <h3>Grappa</h3>
      { brandyList.map((item, i) =>
        <Distillates key={i} category={item.category} list={item.drinks}/>
      )}

      <h3>Whisky</h3>
      { whiskyList.map((item, i) =>
        <Distillates key={i} category={item.category} list={item.drinks}/>
      )}

      <h3>Gin</h3>
      { ginList.map((drink, i) =>
        <LineItem
          key={i}
          name={drink.name}
          price={<>{drink.price} €</>}
        />
      )}

      <h3>Vodka</h3>
      { vodkaList.map((drink, i) =>
        <LineItem
          key={i}
          name={drink.name}
          price={<>{drink.price} €</>}
        />
      )}
    </Styles.PageMenu>
  );
}

const Distillates = ({category, list}) => {
  return (
    <>
      <h4>{category}</h4>
      { list.map((drink, i) =>
        <LineItem
          key={i}
          name={drink.name}
          price={<>{drink.price} €</>}
        />
      )}
    </>
  );
}