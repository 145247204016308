const spritzList = [
  {
    name : "Aperol Spritz",
    ingredients: "(Prosecco, Aperol, Soda)",
    price: "5.00"
  },
  {
    name : "Campari Spritz",
    ingredients: "(Prosecco, Bitter Campari, Soda)",
    price: "5.00"
  },
  {
    name : "Hugo spritz",
    ingredients: "(Prosecco, Fiori di Sambuco)",
    price: "6.00"
  },
  {
    name : "Select Spritz",
    ingredients: "(Prosecco, Select Bitter, Soda)",
    price: "5.00"
  },
];

export default spritzList;