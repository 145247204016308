import React from 'react';
import { useTranslation } from "react-i18next";
import Head from '../../layouts/head';

import Footer from '../../components/footer';
import * as Divider from '../../styles/dividerStyles';
import * as Hr from '../../styles/hrStyles';

export default function Page() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('policy_privacy-title')}
        path='privacy'
      />
      <Main/>
      <Footer/>
    </>
  );
}

const Main = () => {
  return (
    <main className="container">
      <Divider.H20/>
      <Section1/>

      <Divider.H20/>
      <Hr.Auto/>
      <Divider.H20/>

      <Section2/>

      <Divider.H20/>
      <Hr.Auto/>
      <Divider.H20/>

      <Section3/>
      <Divider.H20/>

      <Section4/>
      <Divider.H20/>

      <Section5/>
      <Divider.H20/>

      <Section6/>
      <Divider.H20/>

      <Section7/>
      <Divider.H20/>

      <Section8/>

      <Divider.H20/>
      <Hr.Auto/>
      <Divider.H20/>

      <Section9/>

      <Divider.H20/>
    </main>
  );
}

const Section1 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="text-center">{t('policy_privacy-section_1-1')}</h1>
      <h3>{t('policy_privacy-section_1-2')}</h3>
      <p>{t('policy_privacy-section_1-3')}</p><br/>
      <p>{t('policy_privacy-section_1-4')}</p><br/>
      <p>{t('policy_privacy-section_1-5')}</p>
    </>
  );
}

const Section2 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('policy_privacy-section_2-1')}</h3>
      <p>{t('policy_privacy-section_2-2')}</p><br/>
      <p>{t('policy_privacy-section_2-3')}</p><br/>
      <p>{t('policy_privacy-section_2-4')}</p><br/>
      <p>{t('policy_privacy-section_2-5')}</p>
    </>
  );
}

const Section3 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('policy_privacy-section_3-1')}</h3>
      <p>{t('policy_privacy-section_3-2')}</p>
    </>
  );
}

const Section4 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('policy_privacy-section_4-1')}</h3>
      <h4>{t('policy_privacy-section_4-2')}</h4>
      <p>{t('policy_privacy-section_4-3')}</p><br/>
      <p>{t('policy_privacy-section_4-4')}</p><br/>
      <p>{t('policy_privacy-section_4-5')}</p><br/>
      <p>{t('policy_privacy-section_4-6')}</p>
    </>
  );
}

const Section5 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4>{t('policy_privacy-section_5-1')}</h4>
      <p>{t('policy_privacy-section_5-2')}</p>
    </>
  );
}

const Section6 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4>{t('policy_privacy-section_6-1')}</h4>
      <p>{t('policy_privacy-section_6-2')}</p>
    </>
  );
}

const Section7 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4>{t('policy_privacy-section_7-1')}</h4>
      <p>{t('policy_privacy-section_7-2')}</p>
    </>
  );
}

const Section8 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4>{t('policy_privacy-section_8-1')}</h4>
      <p>{t('policy_privacy-section_8-2')}</p>
    </>
  );
}

const Section9 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('policy_privacy-section_9-1')}</h3>
      <p>{t('policy_privacy-section_9-2')}</p><br/>
      <p>{t('policy_privacy-section_9-3')}</p><br/>
      <p>{t('policy_privacy-section_9-4')}</p><br/>
      <p>{t('policy_privacy-section_9-5')}</p><br/>
      <p>{t('policy_privacy-section_9-6')}</p><br/>
    </>
  );
}