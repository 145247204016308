import React from 'react';

import * as Styles from './styles';

export default function Card({img, title, to}) {
  return (
    <Styles.IndexCard smooth to={to}>
      <Styles.IndexCardImg style={{backgroundImage: `url('/assets/img/menu/${img}.webp')`}}>
        <Styles.IndexCardTitle>{title}</Styles.IndexCardTitle>
      </Styles.IndexCardImg>
    </Styles.IndexCard>
  );
}