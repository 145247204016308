const cocktailClassicSecondList = [
  {
    name : "Boulevardier",
    ingredients: "(Bitter Campari, Carpano rosso, Wild Turkey Rye)",
  },
  {
    name : "Blood and Sand",
    ingredients: "(Scotch Wiskey, succo di arancia rossa, Sweet vermouth, Cherry Brandy)",
  },
  {
    name : "Bloody Mary",
    ingredients: "(Vodka skyy, Succo di Pomodoro, succo di limone, sale al sedano, Tabasco, Worcestershire)",
  },
  {
    name : "Champagne Cocktail",
    ingredients: "(Cognac, Grand Marnier, zucchero, Angostura Bitter, Top Prosecco)",
  },
  {
    name : "Cocktail Martini",
    ingredients: "(Gin Bikens, Vermouth Dry)",
  },
  {
    name : "De La Louisiane",
    ingredients: "(Wild Turkey Bourbon, Carpano Rosso, Dom Benedectine, Assenzio, Peychaud bitter)",
  },
  {
    name : "God Father",
    ingredients: "(Bourbon Whiskey, Amaretto)",
  },
  {
    name : "Long Island",
    ingredients: "(Tequila, Gin, triple sec, Vodka, Rum bianco, Top di coca cola)",
  },
  {
    name : "Mai Tai",
    ingredients: "(Kingston White Rum, Kingston Gold Rum, succo di limone, Orange Curaçao, Orzata)",
  },
  {
    name : "Manhattan",
    ingredients: "(Wild Turkey Rye, Carpano rosso, Angostura Bitter)",
  },
  {
    name : "Old Fashioned",
    ingredients: "(Wild Turkey Bourbon, zucchero, Angostura Bitter)",
  },
  {
    name : "Paloma",
    ingredients: "(Tequila Espolon, succo di limone, Sciroppo d’Agave, Top Soda al Pompelmo)",
  },
  {
    name : "Pornostar Martini",
    ingredients: "(Vodka alla vaniglia, liquore al frutto della passione, uno shot di Champagne)",
  },
  {
    name : "Three Miles",
    ingredients: "(Rum Kingston Bianco, Cognac, succo di limone, Granatina)",
  },
  {
    name : "Vesper Martini",
    ingredients: "(Gin Bikens, Vodka Skyy, Vermout Dry)",
  },
];

export default cocktailClassicSecondList;