import styled from "styled-components";

const COLOR_TEXT = "black";
const COLOR_TEXT_INGREDIENTS = "grey";

export const Item = styled.div`
  display: flex;

  margin: 25px 0px 15px 0px;
`;

export const Name = styled.div`
  padding-right: 10px;

  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;

  color: ${COLOR_TEXT};
`;

export const LineSpace = styled.div`
  border-bottom: 1px solid ${COLOR_TEXT};;

  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
`;

export const Price = styled.div`
  padding-left: 10px;

  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;

  text-align: left;
  color: ${COLOR_TEXT};
`;

export const Ingredients = styled.i`
  margin-bottom: 25px;

  color: ${COLOR_TEXT_INGREDIENTS};
`;
