import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;

  z-index: 1;
  position: fixed;
  top: 15px;
  left: 10px;

  background-color: transparent;
`;

export const LangBtn = styled.a`
  border-radius: 50px;
  padding: 11px 9px;

  text-decoration: none !important;
  cursor: pointer;
  color: black !important;

  &:hover {
    transform: scale(1.5);
  }
`;

export const Flag = styled.img`
  position: relative;
  height: 32px;
`;