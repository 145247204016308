import styled from "styled-components";
import { HashLink } from 'react-router-hash-link';
import { Link } from "react-router-dom";

const COLOR_TEXT = "black";

export const Main = styled.main`
  padding: 20px 15px;

  @media (max-width: 500px) {
    padding: 20px 10px;
  }
`;

export const ContainerMenu = styled.div`
  margin: auto;
  max-width: 1100px;
`;

export const Logo = styled.img`
  margin: auto;
  height: 250px;
`;

export const ContainerIndex = styled.section`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  @media (max-width: 620px) {
    grid-template-columns: 1fr;
  }
`;

export const BtnToIndex = styled(HashLink)`
  position: fixed;
  bottom: 15px;
  right: 30px;

  border: 1px solid white;
  padding: 1rem 1.5rem;

  font-size: 1rem;
  text-decoration: none !important;
  cursor: pointer;
  background-color: ${({ theme }) => theme.btnBgPrimary};
  border-color: ${({ theme }) => theme.btnBorderPrimary};
  color: white !important;

  &:hover {
    background-color: ${({ theme }) => theme.btnBgPrimaryHover};
    border-color: ${({ theme }) => theme.btnBorderPrimaryHover};
  }
`;

export const BtnToWebSite = styled(Link)`
  position: fixed;
  bottom: 15px;
  left: 30px;

  border: 1px solid white;
  padding: 1rem 1.5rem;

  font-size: 1rem;
  text-decoration: none !important;
  cursor: pointer;
  background-color: ${({ theme }) => theme.btnBgPrimary};
  border-color: ${({ theme }) => theme.btnBorderPrimary};
  color: white !important;

  &:hover {
    background-color: ${({ theme }) => theme.btnBgPrimaryHover};
    border-color: ${({ theme }) => theme.btnBorderPrimaryHover};
  }
`;

export const PageMenu = styled.div`
  padding: 25px 15px;

  background-color: white;

  @media (max-width: 620px) {
    padding: 25px 8px;
  }

  @media (max-width: 500px) {
    padding: 25px 4px;
  }

  h1 {
    text-align: center;
    color: ${COLOR_TEXT} !important;
    font-size: 3.5rem;
  }
  h2 {
    margin: 20px 0px 40px 0px;

    text-align: center;
    color: ${COLOR_TEXT} !important;
    font-size: 3.1rem;
  }
  h3 {
    margin: 35px 0px 15px 0px;

    text-align: center;
    color: ${COLOR_TEXT} !important;
    font-size: 1.8rem;
    text-decoration: underline;
  }
  h4 {
    padding-top: 10px;
    text-align: center;
    color: ${COLOR_TEXT} !important;
    font-size: 1.25rem;
  }

  p, small {
    color: ${COLOR_TEXT} !important;
  }

  tr {
    color: ${COLOR_TEXT} !important;
  }
  tr:nth-child(even) {
    background: lightgrey !important;
  }
  tr:nth-child(odd) {
    background: none !important;
  }
`;

export const UlAllergenes = styled.ul`
  list-style-type: decimal;
  padding-inline-start: 30px;

  color: ${COLOR_TEXT} !important;
`;

export const MenuPageDivider = styled.hr`
  margin: 30px auto;
  width: 85px;
  background: white
`;