import React from 'react';

import * as Styles from './styles';

export default function LineItem({name, price, ingredients}) {
  return (
    <>
      <Styles.Item>
        <Styles.Name>{name}</Styles.Name>

        <Styles.LineSpace/>

        <Styles.Price>{price}</Styles.Price>
      </Styles.Item>

      <Styles.Ingredients>{ingredients}</Styles.Ingredients>
    </>
  );
}