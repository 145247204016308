import React from 'react';

import url from '../../../../config/url.json';

import * as Styles from './styles';

export default function LangBtns() {
  function handleChangeLanguage(url){
    window.location.assign("/" + url);
  }

  return (
    <Styles.Container>
      <Styles.LangBtn onClick={() => handleChangeLanguage(url.it.menu)}>
        <Styles.Flag src="/assets/img/flag/it.svg" alt="Italiano"/>
      </Styles.LangBtn>
      <Styles.LangBtn onClick={() => handleChangeLanguage(url.en.menu)}>
        <Styles.Flag src="/assets/img/flag/uk.svg" alt="English"/>
      </Styles.LangBtn>
      <Styles.LangBtn onClick={() => handleChangeLanguage(url.fr.menu)}>
        <Styles.Flag src="/assets/img/flag/fr.svg" alt="Francais"/>
      </Styles.LangBtn>

      <Styles.LangBtn onClick={() => handleChangeLanguage(url.de.menu)}>
        <Styles.Flag src="/assets/img/flag/de.svg" alt="German"/>
      </Styles.LangBtn>
      <Styles.LangBtn onClick={() => handleChangeLanguage(url.es.menu)}>
        <Styles.Flag src="/assets/img/flag/es.svg" alt="Spanish"/>
      </Styles.LangBtn>
      <Styles.LangBtn onClick={() => handleChangeLanguage(url.se.menu)}>
        <Styles.Flag src="/assets/img/flag/se.svg" alt="Swedish"/>
      </Styles.LangBtn>
    </Styles.Container>
  );
}