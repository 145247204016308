/* v 0.9 */
import { createGlobalStyle } from "styled-components";

const FONT_FAMILY_H = `Satisfy`;
const FONT_FAMILY_NORMAL = `Josefin Sans`;
const FONT_FAMILY_BOLD = `Josefin Sans`;

const COLOR_BACKGROUND = ({ theme }) => theme.bg;
const COLOR_TEXT = ({ theme }) => theme.text;
const COLOR_H = ({ theme }) => theme.title;
const COLOR_INPUT_BORDER = ({ theme }) => theme.inputBorder;
const COLOR_INPUT_BACKGROUND = ({ theme }) => theme.inputBackground;

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;

    line-height: 1.0;
    -webkit-text-size-adjust: 100%;

    @media (max-width: 500px) {
      font-size: 95%;
    }

    @media (max-width: 400px) {
      font-size: 90%;
    }
  }
  body {
    margin: 0px;
    height: 100%;

    background: ${COLOR_BACKGROUND};
    color: ${COLOR_TEXT};
    font-family: ${FONT_FAMILY_NORMAL};
    letter-spacing: .5px;
  }
  main { display: block; }

  h1, h2, h3, h4, h5, h6 {
    margin: 0px;
    color: ${COLOR_H};
    font-family: ${FONT_FAMILY_H};
  }
  h1 { font-size: 2.5rem; line-height: 3rem; }
  h2 { font-size: 2rem; line-height: 2.5rem; }
  h3 { font-size: 1.6rem; line-height: 2.1rem; }
  h4 { font-size: 1.4rem; line-height: 1.9rem; }
  h5 { font-size: 1.125rem; line-height: 1.575rem }
  h6 { font-size: 1rem; }

  a, a:active, a:focus {
    font-family: ${FONT_FAMILY_BOLD};
    color: ${COLOR_TEXT};
    text-decoration: none;
    line-height: 1.2rem;
    cursor: pointer;
    transition: 0.3s;
  }
  a:hover { text-decoration: underline; }
  a:visited { color: inherit; }
  a:-webkit-any-link { color: inherit; }

  button {
    font-family: ${FONT_FAMILY_BOLD};
    letter-spacing: .5px;
    line-height: 1.2rem;
    transition: 0.3s;
  }
  button:active {
    transform: scale(0.95);
  }

  label {
    margin-left: 10px;
  }

  input, select, textarea {
    width: 100%;
    margin: 5px 0px 5px 0px;
    padding: 9px 10px;
    line-height: normal;
    color: ${COLOR_TEXT};
    border: 1px solid ${COLOR_INPUT_BORDER};
  }
  input[type='checkbox'], [type='radio'] {
    margin: 0px;
    cursor: pointer;
  }
  input[type="text"], input[type="password"], input[type="email"], select, textarea {
    background-color : ${COLOR_INPUT_BACKGROUND};
  }
  select option:disabled {
    color: rgb(0, 0, 0, 0.5);
    color: rgb(0, 0, 0, 0.1);
  }

  p {
    margin: 0px;
    line-height: 1.5rem;

    @media (max-width: 768px) {
      font-size: 90%;
    }
  }

  small {
    font-size: 80%;
  }
  strong {
    font-family: ${FONT_FAMILY_BOLD};
  }

  ul {
    margin-block-start: 0px;
    margin-block-end: 0px
  }
  li {
    padding: 8px 5px
  }

  hr {
    border-width: 0px;
    height:2px;
  }
  img {
    border-style: none;
  }
  table {
    border-spacing: 0px;
    width: 100%;
  }
  tr:nth-child(even) {
    background: ${({ theme }) => theme.tableBgTrOdd}
  }
  tr:nth-child(odd) {
    background: ${({ theme }) => theme.tableBgTrEven}
  }
  th {
    padding: 6px;
  }
  td {
    padding: 6px;
  }
`;