import React from 'react';
import { useTranslation } from "react-i18next";

import LineItem from '../components/lineItem';

import * as Styles from '../styles';
import * as Divider from '../../../styles/dividerStyles';

export default function PageFoods() {
  const { t, ready } = useTranslation();

  if (!ready) return "loading translations...";

  const foodList = t('foodList', { returnObjects: true });

  return (
    <Styles.PageMenu id={t('menu-title_food_tag')}>
      <h2>{t('menu-title_food')}</h2>

      <p className='text-center'>
        {t('menu-kitchen_schedule')}<br/>
        {t('menu-kitchen_hours')}
      </p>

      { foodList.map((item, i) =>
        <CategoryFood key={i} name={item.category} list={item.foods}/>
      )}

      <Divider.H30/>

      <p className='text-center'>
        {t('menu-choice_sauces')}<br/>
        {t('menu-sauces')}<br/>
        {t('menu-info')}
      </p>
    </Styles.PageMenu>
  );
}

const CategoryFood = ({name, list}) => {
  return (
    <>
      <h3>{name}</h3>
      { list.map((food, i) =>
        <LineItem
          key={i}
          name={food.name}
          price={<>{food.price} €</>}
          ingredients={food.ingredients}
        />
      )}
    </>
  );
}