const cocktailClassicList = [
  {
    name : "Negroni",
    ingredients: "(Bitter Campari, Carpano rosso, gin Bikens)",
  },
  {
    name : "Americano",
    ingredients: "(Bitter Campari, Carpano rosso, Soda)",
  },
  {
    name : "Aviation",
    ingredients: "(Bikens gin, Maraschino, succo di limone, Liquore di violette)",
  },
  {
    name : "Black Russian",
    ingredients: "(Vodka Skyy, Kalua)",
  },
  {
    name : "Cosmopolitan",
    ingredients: "(Vodka Skyy, succo di limone, triple seec, succo di cranberry)",
  },
  {
    name : "Alaska",
    ingredients: "(Gin Bikens, Chartreuse Gialla, Orange Bitter)",
  },
  {
    name : "Clover Club",
    ingredients: "(Gin Bikens, Succo di Limone, Purea di Lamponi, Albume)",
  },
  {
    name : "Harvey Wallbanger",
    ingredients: "(Vodka Skyy, Galliano, Succo di Arancia)",
  },
  {
    name : "Kir",
    ingredients: "(Vino Bianco, Creme de Cassis)",
  },
  {
    name : "Kir Royale",
    ingredients: "(Prosecco, Creme de Cassis)",
  },
  {
    name : "Pina Colada",
    ingredients: "(Kingston White Rum, Succo D’Ananas, Purea di Cocco)",
  },
  {
    name : "Pimm’s Cup",
    ingredients: "(Pimm’s, Limonata)",
  },
  {
    name : "Whiskey Sour",
    ingredients: "(Whiskey, Succo di Limone, Sciroppo di zucchero, Albume)",
  },
];

export default cocktailClassicList;