import React from 'react';
import { useTranslation } from "react-i18next";

import LineItem from '../components/lineItem';

import * as Styles from '../styles';

import cocktailVintageList from '../../../menu/cocktailVintageList';
import cocktailPremiumList from '../../../menu/cocktailPremiumList';
import cocktailClassicList from '../../../menu/cocktailClassicList';
import cocktailClassicSecondList from '../../../menu/cocktailClassicSecondList';
import cocktailIndustrialSipsList from '../../../menu/industrialSipsList';
import spritzList from '../../../menu/spritzList';
import cocktailAnalcoholicList from '../../../menu/cocktailAnalcoholicList';

export default function PageCocktail() {
  const { t } = useTranslation();

  return (
    <Styles.PageMenu id={t('menu-title_cocktail_tag')}>
      <h2>{t('menu-title_cocktail')}</h2>

      <h3>{t('menu-cocktail_vintage')}</h3>
      { cocktailVintageList.map((cocktail, i) =>
        <LineItem
          key={i}
          name={cocktail.name}
          price={<>{cocktail.price} €</>}
          ingredients={cocktail.ingredients}
        />
      )}

      <h3>{t('menu-cocktail_premium')}</h3>
      { cocktailPremiumList.map((cocktail, i) =>
        <LineItem
          key={i}
          name={cocktail.name}
          price={<>8.00 €</>}
          ingredients={cocktail.ingredients}
        />
      )}

      <h3>Industrial Sips (signature cocktail)</h3>
      { cocktailIndustrialSipsList.map((cocktail, i) =>
        <LineItem
          key={i}
          name={cocktail.name}
          price={<>8.00 €</>}
          ingredients={cocktail.ingredients}
        />
      )}

      <h3>{t('menu-cocktail_classic')}</h3>
      { cocktailClassicList.map((cocktail, i) =>
        <LineItem
          key={i}
          name={cocktail.name}
          price={<>6.00 €</>}
          ingredients={cocktail.ingredients}
        />
      )}

      <h3>{t('menu-cocktail_classic')}</h3>
      { cocktailClassicSecondList.map((cocktail, i) =>
        <LineItem
          key={i}
          name={cocktail.name}
          price={<>7.00 €</>}
          ingredients={cocktail.ingredients}
        />
      )}

      <h3>{t('menu-cocktail_spritz')}</h3>
      { spritzList.map((cocktail, i) =>
        <LineItem
          key={i}
          name={cocktail.name}
          price={<>{cocktail.price} €</>}
          ingredients={cocktail.ingredients}
        />
      )}

      <h3>{t('menu-cocktail_analcoholic')}</h3>
      { cocktailAnalcoholicList.map((cocktail, i) =>
        <LineItem
          key={i}
          name={cocktail.name}
          price={<>5.00 €</>}
          ingredients={cocktail.ingredients}
        />
      )}
    </Styles.PageMenu>
  );
}