import { createContext } from "react";
import { ThemeProvider } from "styled-components";
import { darkTheme } from "../../styles/theme";

const ThemeContext = createContext( null );

export const ThemeApp = ({ children }) => {
  const themeStyle = darkTheme;

  return (
    <ThemeContext.Provider value={{}}>
      <ThemeProvider theme={themeStyle}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

export default ThemeContext;