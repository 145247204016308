import React from 'react';
import { useTranslation } from "react-i18next";

import * as Styles from '../styles';
import LineItem from '../components/lineItem';

import wineList from '../../../menu/wineList';

export default function PageWines() {
  const { t } = useTranslation();

  return (
    <Styles.PageMenu id={t('menu-title_wine_tag')}>
      <h2>{t('menu-title_wine')}</h2>

      { wineList.map((item, i) =>
        <Brand key={i} name={item.brand} list={item.list}/>
      )}
    </Styles.PageMenu>
  );
}

const Brand = ({name, list}) => {
  return (
    <>
      <h3>{name}</h3>
      { list.map((item, i) =>
        <Category key={i} title={item.category} list={item.wines}/>
      )}
    </>
  );
}

const Category = ({title, list}) => {
  return (
    <>
      <h4>{title}</h4>

      { list.map((wine, i) =>
        <LineItem
          key={i}
          name={wine.name}
          price={<>{wine.bottle} € {wine.glass && <>{wine.glass} €</>} </>}
        />
      )}
    </>
  );
}